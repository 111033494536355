header{
    position: fixed;
    z-index: 11;
    /* background-color: var(--White-Background); */
    color: var(--Text-Black);
    width: 100%;
    left: 0;
    align-items: center;
    justify-content: space-around;
}

.headerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.headerBar{
    display: flex;
    width: 100%;
    min-height: 50px;
    background-color: var(--Main-Light-Green);
}
.logo{
    margin-bottom: -10px;
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
}
.nav{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    height:75px;
}
.navItemContainer{
    display: flex;
    width:50%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.logoImg{
    height:250px;
    margin: 0px;
    margin-bottom:-0px;
}
.mobileButton{
    display: none;
}
.navLogo{
    display: flex;
    width: 100%;
    max-width: 422px;
    height: 100%;
    background-color: var(--White-Background);
    transition: 0.3s;
    align-items: center;
}
.navLogo img{
    display: flex;
    height:60px;
    padding:12.5px;
    object-fit: contain;
}
.navLogo:hover {
    background-color: var(--Medium-Green-Accent);
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .mobileLogo{
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: var(--White-Background);
        transition: 0.3s;
        padding-left:2%;
        padding-right: 2%;
        cursor: pointer;
    }
    .nav{
        justify-content: space-between;
        background-color: var(--White-Background);
    }
    .mobileButton{
        display: flex;
        font-size: 40px;
        width:30%;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s;
    }
    .mobileMenu{
        display:flex;
        width: 100%;
        height:100vh;
        justify-content: right;
    }
    .mobileMenu ul{
        background-color: var(--Light-Grey-Accent);
        text-align: left;
        list-style-type: none;
        padding-left: 0px;;
        width: 70%;
        margin:0;
        height:fit-content;
    }
    .mobileMenu ul ul{
        padding-left:30px
    }
    .mobileMenu ul li{
        padding:5px;
        padding-left:10px;
        transition: 0.3s;
        cursor: pointer;
    }
    .mobileMenu ul li:hover{
       background-color: var(--Medium-Green-Accent);
    }

    header .nav ul{
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    header .nav ul a{
        width: 100%;
    }
    .logo{
        display: flex;
    }
    .logoText{
        display: flex;
        width: 320px;
        align-items: center;
    }

  
}