.menuItems{
    display:inline-block;
    position: relative;
    width: 100%;
    height:100%;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}
.menuLink{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    background-color: white;
}
.menuLink p {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.menuItems:hover .menuLink{
    background-color: var(--Medium-Green-Accent);
}
.menuItems:hover .dropdown{
    display: inline-block;
}

.image{
    object-fit: cover;
    width: 100%;
    /* max-height: 90px; */
}


@media screen and (max-width: 720px) {
    .topLevelNavItem{
        justify-content: center;
        align-items: center;
        min-width: 20%;
        height: 50px;
        margin: 0px;
        transition: 0.3s;
        border-width: 1px;
        border-style: solid;
        border-color: var(--Medium-Green-Accent);
    }
}