footer{
    font-family: "Questrial-Regular";
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: var(--Main-Light-Green);

}
footer p{
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 0px;
}
footer a{
    display: flex;
    text-align: center;
    justify-content: center;
}
.terms{
    margin-top: 10px;
    font-size: 15px;
}
