#wbgn{
    background-color: var(--White-Background);
}
#lgrn{
    background-color: var(--Light-Green-Accent);

    margin-bottom: 100px;
}
#lgry{
    background-color: var(--Light-Grey-Accent);
}
.toolsPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}
.itemContainer{
    display: flex;
    flex-direction: row;
}
.toolItem{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 30%;
    margin-bottom: 30px;
}
.btnContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5px;
}
.btnContainer a{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.toolsPage section{
    display: flex;
    position: relative;
    min-width: 90%;
    max-width: 90%;
    margin-top: 1%;
    margin-bottom: 2%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-margin: 100px;
}
.toolsPage .cardTitle{
    display: flex;
    text-align: center;
    align-self: center;
    font-size: 45.6px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--Text-Black);
}
.toolsPage .opacity{
    position: absolute;
    opacity: 0.8;
    padding: 0px;
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
    height: 90%;
    z-index: 1;
}
.toolsPage .titleSection{
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
}
.toolsPage .titleContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin-top: 20px;
    margin-bottom: 50px;
    z-index: 2;
}
.titleSection .titleContainer h1{
    display: flex;
    color: var(--Main-Light-Green);
    font-size: 64px;
    margin: 0px;
    font-weight: normal;
}
.titleSection .titleContainer p{
    display: flex;
    color: var(--Text-Black);
    font-family: "Questrial-Regular";
    text-align: center;
    font-size: 24px;
}
.titleContainer{
    display: flex;
}
.cardTitle{
    display: flex;
    color: var(--Text-Black);
    align-self: center;
}
.cardButton{
    display: flex;
    border-width: 5px;
    border-style: solid;
    border-color: var(--Main-Light-Green);
    color: var(--White-Background);
    background-color: var(--Main-Light-Green);
    padding: 10px;
    text-align: right;
    justify-content: center;
    vertical-align: middle;
    font-size: 16.8px;
    transition: 0.3s;
    min-width: 90px;
}
.cardButton:hover{
    color:var(--White-Background);
    background-color: var(--Dark-Green-Accent);
    border-color: var(--Dark-Green-Accent);
    border-width: 5px;
    border-style: solid;
}
.toolItem .toolText{
    display: flex;
    color: var(--Text-Black);
    font-family: "Raleway";
    font-size: 18px;
    line-height: 1.5em;
    text-align: center;
    margin: 10px;
    margin-top: 0px;
}
/* .toolBullets .toolText{
    text-align: left;
} */
.toolBullets{
    display:flex;   
    flex-direction:column;
    width:50%;
    color: var(--Text-Black);
}
#leftMargin{
    margin-left: 60px;
}   
.toolBullets h3{
    font-family: "Montserrat";
    width: 90%;
    text-align: left;
    padding-bottom: 0;
    margin-bottom: 0;
}
.toolBullets ul{
    margin-top: 0;
}
.toolBullets ul li{
    font-family: "Raleway";
    font-size: 18px;
    line-height: 1.5em;
    margin-bottom: 30px;
    /* text-align: center; */
}
.space{
    display: flex;
    min-width: 20px;
}
#mobileVirtuosoBtn{
    display: none;
}
#desktopVirtuosoBtn{
    display: flex;
    align-self: center;
    margin-top: 10%;
}
.rowToColumn{
    display: flex;
    flex-direction: row;
}


@media screen and (max-width: 1200px) {
    .itemContainer{
        flex-direction: column;
        width: 100%;
    }
    .rowToColumn{
        flex-direction: column;
    }
    #mobileVirtuosoBtn{
        display: flex;
    }
    #desktopVirtuosoBtn{
        display: none;
    }
    .toolsPage .toolItem{
        flex-direction: column;
        margin-bottom: 60px;
    }
    .toolsPage .cardTitle{
        margin-left: 0px;
    }
    .toolsPage .cardButton{
        text-align: center;
    }
    .titleContainer{
        width: 90%;
    }
    .toolBullets{
        width: 100%;
    }
    .toolsPage .opacity{
        height: 95%;
    }
    #leftMargin{
        margin-left: 0px;
    }
}