
.landingImage{
    margin-top: 10px;
    width: 100%;
}
.welcomePageIntro{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: -40px;
}
.welcomePageIntro img{
    display: flex;
    width: 50%;
    height:40vw;
    object-fit: cover;
    object-position: 0% 35%;
}
.welcome{
    display:flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    width: 100%;
    justify-content: center;
}

.welcomeText{
    font-family: 'PlayfairDisplay';
    display: flex;
    font-size: 70px;
    font-weight: bold;
    color: #AEBDAC;
    margin: 0px;
}
.specialtiesText{
    font-family: 'Montserrat';
    color: var(--Dark-Green-Accent);
    display: flex;
    font-size: 50px;
    font-weight: bold;
    margin: 0px;
    padding-bottom: 30px;
}
.infoCard{
    display: flex;
    margin-top: 90px;
    width:80%;
}
.introCardDesc{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding-right: 5%;
    padding-left:5%;
    width: 40%;
}
.cardDesc{
    display: flex;
    text-align: right;
    flex-direction: column;
    align-items:flex-end;
    justify-content: center;
    padding-right: 85px;
}
.homeCardTitle{
    display: flex;
    font-size: 50px;
    font-weight: bold;
    color: #AEBDAC;
    margin: 0px;
    font-family: 'Montserrat';
}
.cardText{
    font-family:"Raleway";
    font-size:20px;
    margin-top:20px;
}
.cardImg{
    display: flex;
    height: 500px;
    width: 500px;
    object-fit: contain;
}
.bridgeImage{
    display: flex;
    height: 500px;
    width: 500px;
    object-fit: cover;
}
.introCardDesc a{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.cardDesc a{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.welcome .cardButton{
    display: flex;
    border-width: 5px;
    border-style: solid;
    border-color: var(--Main-Light-Green);
    color:var(--White-Background);
    background-color: var(--Main-Light-Green);
    padding:10px;
    text-align: right;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 16.8px;
    transition: 0.3s;
}
.cardButton p {
    margin: 0px;
}
.welcome .cardButton:hover{
    color:var(--White-Background);
    background-color: var(--Dark-Green-Accent);
    border-color: var(--Dark-Green-Accent);
    border-width: 5px;
    border-style: solid;
    
}
.MySpecialties .cardButton{
    font-family: "PlayfairDisplay";
    color:var(--White-Background);
    background-color: var(--Dark-Green-Accent);
    border-color: var(--Dark-Green-Accent);
    
    display: flex;
    border-width: 5px;
    border-style: solid;
    padding:10px;
    text-align: right;
    justify-content: center;
    vertical-align: middle;
    font-size: 16.8px;
    transition: 0.3s;
}
.MySpecialties .cardButton:hover{
    border-color: var(--Main-Light-Green);
    color:var(--White-Background);
    background-color: var(--Main-Light-Green);
    border-width: 5px;
    border-style: solid;
}

.infoCard-r{
    display: flex;
    margin-top: 90px;
    width:80%;
}

.infoCard-r .cardDesc{
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    padding-left: 85px;
}
.infoCard-r .cardDesc .cardTitle{
    display: flex;
    font-size: 27.5px;
}
.infoCard-r .cardDesc .cardText{
    display: flex;
    font-family:"Raleway";
    font-size:20px;
    margin-top:20px;
}
.infoCard-r .cardImg{
    display: flex;
    max-width: 55%;
    object-fit: contain;
}
.infoCard-r .cardDesc .cardButton{
    display: flex;
    border-width: 5px;
    border-style: solid;
    padding:10px;
    text-align: left;
    justify-content: center;
    vertical-align: middle;
    font-size: 16.8px;
}

.infoCard-r a{
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: flex-start;
}

.MySpecialties{
    display:flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    width: 100%;
    justify-content: center;
    margin-top: 100px;
    padding-bottom: 70px;
    background-color: var(--Light-Grey-Accent);
}
.specContainer{
    display: flex;
    flex-direction: row;
    width: 80%;
}
.left{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-right: 50px;
    justify-content: center;
}
.specText{
    display: flex;
    flex-direction: column;
    font-family: "Raleway";
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1em;
    font-size:20px;
    width: 50%;
    padding:0;
    margin: 0;
}
.test{
    margin-bottom: 0px;
}
.specText ul{
    display: flex;
    flex-direction: column;
    line-height: 1em;
    align-items: left;
    width: 100%;
}
.specText ul li{
    transition: 0.3s;
}
.specText ul li:hover{
    background-color: var(--Main-Light-Green);
    /* align-self: center; */
}
.categoriesList{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 50px;
}
.categoriesList div{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 33.3%;
    transition: 0.3s;
}
.categoriesList div a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}
.categoriesList div:hover{
    background-color: var(--Dark-Green-Accent);
}
.categoriesList div a:hover{
    color: white;
}
.righthandLine{
    border-right: 1px solid var(--Dark-Green-Accent);
}
.profile{
    display: flex;
    object-fit: cover;
    width:100%;
}
.advantages{
    margin-top: 75px;
}
.advTitle{
    color:var(--Text-Black);
    font-size: 50px;
    font-family: 'Montserrat';
    margin-top:0px;
}
.pointContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;
    font-family: "Raleway";
}
.pointBox1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    background-color: var(--Text-Black);
    min-height: 400px;
    width:33.33%
}
.pointBox2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    background-color: var(--White-Background);
    min-height: 400px;
    width:33.33%
}
.pointBox3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    background-color: var(--Main-Light-Green);
    min-height: 400px;
    width:33.33%
}
.line{
    display: flex;
    align-items: flex-end;
    font-size: 60px;
    height: 10%;
}
.w-line{
    display: flex;
    align-items: flex-end;
    color: var(--White-Background);
    font-size: 60px;
    height: 10%;
}
.pointHeading{
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 45.6px;
    margin-top: 13px;
    height: 30%;
    font-family: 'Montserrat';
}
.pointText{
    display: flex;
    font-family: "Questrial-Regular";
    padding-top: 10px;
    align-items: flex-start;
    font-size: 20px;
    max-width: 60%;
    height: 50%;
}
.headBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30%;
}
#w{
    color: var(--White-Background);
}
#g{
    color: var(--Medium-Green-Accent);
}
#b{
    color: var(--Text-Black);
}

@media screen and (max-width:750px) {
    .welcomePageIntro{
        flex-direction: column-reverse;
        width: 100%;
    }
    .welcomePageIntro img{
        width: 100%;
        height: 100vw;
    }
    .introCardDesc{
        width: 100%;
        padding: 0;
    }
    .welcomeText{
        font-size: 60px;
    }
    p{
        font-size: 20px;
    }
    .advTitle{
        font-size: 40px;
    }
}

@media screen and (max-width: 1200px) {
    .specialtiesText{
        line-height: 1em;
    }
    .cardText{
        margin: 2%;
    }
    .pointContainer{
        flex-direction: column;
    }
    .pointBox1{
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        padding-top: 20px;
        background-color: var(--Text-Black);
        min-height: 300px;
        width:100%
    }
    .pointBox2{
        display: flex;
        background-color: var(--White-Background);
        padding-bottom: 20px;
        padding-top: 0px;
        min-height: 300px;
        width:100%
    }
    .pointBox3{
        display: flex;
        background-color: var(--Medium-Green-Accent);
        padding-bottom: 20px;
        padding-top: 20px;
        min-height: 300px;
        width:100%
    }
    .infoCard{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 55px;
        width:100%;
    }
    
    .cardDesc{
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 20px;
    }
    .cardImg{
        display: flex;
        max-width: 80%;
        object-fit: contain;
    }
    .infoCard a {
        display: flex;

        margin-top: 20px;
        justify-content: center;
    }

    .infoCard-r{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 55px;
        width:100%;
    }
    .infoCard-r .cardDesc{
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 20px;
    }
   
    .infoCard-r .cardImg{
        display: flex;
        max-width: 80%;
        object-fit: contain;
    }
    .infoCard-r .cardDesc .cardButton{
        display: flex;
        border-width: 5px;
        border-style: solid;
        padding:10px;
        text-align: center;
        vertical-align: middle;
        font-size: 16.8px;
    }
    .infoCard-r a {
        display: flex;
        width: 100%;
        margin-top: 20px;
        justify-content: center;
    }
    .specContainer{
        display: flex;
        flex-direction: column-reverse;
        width: 80%;
    }
    .left{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        margin-right:0px;
        margin-top:20px;
        justify-content: center;
    }
    .specText{
        display: flex;
        font-family: "Questrial-Regular";
        align-items: center;
        text-align: left;
        font-size:24px;
        width: 100%;
    }
    .bridgeImage{
        object-fit: cover;
        height: 300px;
        width: 80%;
    }
    .infoCard-r .cardImg{
        object-fit: cover;
        height: 300px;
        width: 80%;
    }
    .test{
        text-align: center;
    }
    .specContainer{
        align-items: center;
        width: 95%;
    }
    
}