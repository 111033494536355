.dropdown{
    display: none;
    position: absolute;
    margin: 0px;
    left: 0px;
    padding: 0px;
    width:90%;
}
.dropdownContent{
    background-color: white;
    padding: 10px;
    transition: 0.3s;
}
.dropdownContent:hover{
    background-color: var(--Main-Light-Green);
}
