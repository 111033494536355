.howItWorksPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.howItWorksPage .theProcess{
    display: flex;
    margin-top: -45px;
    /* border: 20px; */
    flex-direction: column;
    justify-content: center;
    align-items: left;
    max-width: 90%;
    align-self: center;
    scroll-margin:75px;
}
.processTitle{
    display: flex;
    color: var(--Text-Black);
    margin-bottom: -10px;
    width: 80%;
    margin-top: 60px;
    font-size: 50px;
    font-weight: bold;
    /* color: #AEBDAC; */
    font-family: 'Montserrat';
}
.processCard{
    display: flex;
    align-items: flex-end;
    margin-top: 30px;
    padding-bottom: 5px;
    /* min-height: 150px; */
    border-bottom: 1px solid;
}
.stepTitle{
    display: flex;
    align-items: flex-end;
    width: 30%;
    color: var(--Main-Light-Green);
    font-size: 25px;
    /* margin-top: 0px; */
    margin-bottom: 0px;
}
.stepText{
    display: flex;
    max-width: 70%;
    color: var(--Text-Black);
    font-family: "Questrial-Regular";
    text-align: left;
    font-size: 16px;
    /* margin-top: 0px; */
    margin-bottom: 0px;
}
.picBanner{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 75px;
    height: 200px;
}
.potPic{
    display: flex;
    width: 33.33333%;
    margin: 1%;
}

.noPosition{
    display: flex;
    width: 100%;
    object-fit: cover;
}

.withPosition{
    display: flex;
    width: 100%;
    object-fit:cover;
    object-position: 15%,100%;
}

.servicesPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-bottom: 4%;
    margin-top: 0px;   
}
.servicesDisplay{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
#servicesTitle{
    text-align: center;
    justify-content: center;
}
.serviceCard{
    display: flex;
    flex-direction: column;
    width: 30%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 3%;
    padding-right: 3%;
    line-height: 2em;
}

.servicesText{
    font-family: "Raleway"; 
    font-size: 16px;
}
.servicesTitle{
    font-size: 16px;
    font-family: "Montserrat"
}
.servicesBullet{
    font-family: "PlayfairDisplay"; 
    font-size: 14px;
}
#mainService{
    font-size: 20px;
}

.quote{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 30px;
}
.quoteTitle{
    justify-content: center;
    display: flex;
    color: var(--Text-Black);
    min-width: 350px;
    margin-bottom: 10px;
    font-size: 50px;
    font-weight: bold;
    /* color: #AEBDAC; */
    font-family: 'Montserrat';
}
.quoteLink{
    display: flex;
    flex-direction: row;
}
.quoteText{
    display: flex;
    align-items: flex-end;
    font-family: "Questrial-Regular";
    color: var(--Text-Black);
    font-size: 20px;
    /* margin-top: 0px; */
    padding-top: 10px;
    padding-bottom: 20px;
    margin: 0;
    
}
.linkAlign{
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
    .processCard{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
        width: 100%;
    }
    .stepText{
        max-width: 100%;
    }
    .howItWorksPage .theProcess{
        max-width: 70%;
    }
}
@media screen and (max-width: 720px) {
    
    .howItWorksPage  .theProcess{
        display: flex;
        margin-top: -45px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        min-width: 100px;
        width: 100%;
       
    }
    .processCard{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        width: 100%;
    }
    .stepText{
        max-width: 100px;
    }
    .servicesDisplay{
        flex-direction: column;
        align-items: center;
    }
    .serviceCard{
        width: 90%;
    }
}
