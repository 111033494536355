.affiliations{
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}
.affiliations .cardButton{
    display: none;
}
.partnerContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
}
.partnerCard{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 30px;
    width:100%;
    /* transition: 0.3s; */
}
.partnerCard:hover{
    background-color: var(--Light-Green-Accent);
    padding: 28px;;
    border-width: 2px;
    border-style: solid;
    border-color: var(--Main-Light-Green);
}
.cardLink{
    display: flex;
    width: 40%;
    background-color: var(--Light-Grey-Accent);
}
.partnerDesc{
    display: flex;
    font-family: "Questrial-Regular";
    line-height: 1.5em;
    font-size: 20px;
    padding: 20px;
    height: 40%;
}
.prtnrImg{
    display: flex;
    height: 175px;
    width: 80%;
    object-fit: scale-down;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.remSpace{
    width: 4rem;
}
.gridContainer {
    padding-top: 1rem;
    display: grid;
    justify-content: center;
    width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-gap: 1rem;
    grid-auto-flow: row;
  }
  .gridItem{
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    transition: 0.3s;
    background-color: var(--Light-Grey-Accent);
  }
  .gridHead{
    display: flex;
    justify-content: center;
    width: 90%;
    /* background-color: var(--Light-Grey-Accent); */
    color: var(--Text-Black);
    margin-top: 1rem;
    font-family: "Montserrat";
  }
  .brndImg{
    display: flex;
    object-fit: scale-down;
    margin: 5px;
    width: 200px;
    height: 200px;
  }
  .headText{
    margin-left: 50px;
    margin-right: 50px;
  }

@media screen and (max-width: 1200px) {
    .partnerContainer{
        flex-direction: column;
        align-items: center;
        width: 90%;
    }
    .partnerCard{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        transition: 0.3s;
        padding:20px;
    }
    .partnerDesc{
        display: flex;
        font-family: "Questrial-Regular";
        line-height: 1.5em;
        font-size: 18px;
        height: 100%;
    }
    .cardLink{
        width: 100%;
        max-width: 100%;
        margin-top: 20px;
        background-color: var(--Light-Grey-Accent);
    }
    .partnerCard:hover{
        background-color: var(--Light-Green-Accent);
    }
    .affiliations .cardButton{
        display: flex;
    }
    .affiliations .cardButton:hover{
        color:var(--White-Background);
        background-color: var(--Dark-Green-Accent);
        border-color: var(--Dark-Green-Accent);
    }
    .prtnrImg{
        display: flex;
        height: 100px;
        object-fit: scale-down;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}