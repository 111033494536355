.formContainer{
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: 50%;
    transform: translateX(50%);
    min-height: 3000px;
}
.titleCard{
    margin-top: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: var(--Light-Grey-Accent);
    padding-bottom: 60px;
}
.title{
    margin-bottom: 30px;
    margin-top: 50px;
    max-width: 60%;
    text-align: center;

}
.titleText{
    font-family: "Questrial-Regular";
    color: var(--Text-Black);
    line-height: 1.15em;
    font-size: 24px;
    width: 45%;
    margin: 2px;
}
.titleText a{
    display: inline;
    text-decoration: underline; 
}
.formTitle{
    display: flex;
    margin-bottom: 20px;
    font-family: "Questrial-Regular";
    color:var(--Main-Light-Green);
    font-size: 52.8px;
}

@media screen and (max-width: 720px) {
    .formContainer{
        display: flex;
        flex-direction: column;
        align-items: left;
        max-width: 100%;
        padding: 10px;
        min-height: none;
        transform: translateX(0%);
    }
    .title{
        margin-bottom: 30px;
        margin-top: 50px;
        max-width: 90%;
        text-align: center;
    
    }
    .titleText{
        font-family: "Questrial-Regular";
        color: var(--Text-Black);
        line-height: 1.15em;
        font-size: 24px;
        width: 90%;
        margin: 2px;
        padding: 10px;
    }
}