.offsitesPage{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.offsitesTitle{
    font-family: 'PlayfairDisplay';
    width: 100%;
    text-align: center;
    font-size: 55px;
    font-weight: bold;
    color: #AEBDAC;
    margin: 0px;
}
.offsitesIntroduction{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    width: 90%;
    align-items: top;
}
.offsitesDescription{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    max-width: 45%;
    padding: 10px;
    padding-bottom:10%;
}  
.offsitesDescription ul{
    list-style-type: none;
    text-align: left;
    font-size:12px;
    line-height: 2em;
    font-family:"Raleway";
}  
.offsitesDescription ul li::before{
    color:#AEBDAC;
    content: "▪";
    display: inline-block;
    width: 1em;
    margin-left: -1em; 
    
}
.offsitesPhotoContainer{
    display: flex;
    flex: 1;
    justify-content: center;
    max-width: 50%;
    padding: 10px;
}
.offsitesForm{
    display: flex;
    flex-direction: column;
    min-width:50%;
    margin-bottom:3rem;
}
.offsitesPhoto{
    display: flex;  
}
.offsitesPhoto img{
    height: 600px;
    object-fit: scale-down;
}

@media screen and (max-width:1150px) {
    .offsitesIntroduction{
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .offsitesDescription{
        max-width: 100%;
        width: 100%;
        padding: 0;
    }
    .offsitesPhotoContainer{
        max-width: 100%;
    }
    .offsitesPhoto{
        max-width: 100%;
        object-fit: contain;
    }

}
@media screen and (max-width:750px) {
    .offsitesPhoto img{
        height: 300px;
        object-fit: scale-down;
    }
}