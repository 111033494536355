.aboutPageContainer{
    display: flex;
    flex-direction:column;
    align-items: center;
    margin-bottom: 20px;
}
.title{
    display: flex;
    font-size: 50px;
    color: var(--Dark-Green-Accent);
}
.titleBackground{
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-color: #AEBDAC;
    color:#FFFFFF; */
}
.sectionTitle{
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: var(--Text-Black);
}
.bioCard{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    margin-top: 50px;
}
.imageContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.bioCard-r{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}
.bioText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
    width: 100%;
}
.squish{
    width: 60%
}
.bioText-r{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.titleAlign{
    display: flex;
    flex-direction: row;
}
.matchWidth{
    display: flex;
    min-width:450px;
}
.bioTitle{
    display: flex;
    font-size: 30px;
    text-align: left;
    justify-content: left;
    margin: 0px;

    color: var(--Text-Black);
}
.bioSubTitle{
    display: flex;
    font-size: 15px;
    text-align: left;
    justify-content: left;
    margin: 0px;
    color: var(--Text-Black);
    margin-bottom: 30px;
}
.bioContent{
    display: flex;
    text-align: left;
    font-family: "Raleway";
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.5em;
    color: var(--Text-Black);
}
.bioTitle-r{
    display: flex;
    font-size: 27.5px;
    text-align: right;
    margin: 0px;
    color: var(--Text-Black);
}
.bioContent-r{
    display: flex;
    text-align: left;
    font-family: "Raleway";
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--Text-Black);
}
.picDiv{
    min-width:450px;
}
.bioPic{
    display: flex;
    width:500px;
    object-fit: contain;
    padding-left: 20%;
}
a{
    display: flex;
    width: 100%;
}
.subTitle{
    display: flex;
    color: var(--Main-Light-Green);
    font-size: 60px;
}

.gridContainer {
    display: grid;
}

.funFacts{
    display: flex;
    width: 100%;
    justify-content:center;
    flex-direction: column;
    background-color: var(--Light-Grey-Accent);
    margin-bottom: 50px;
    padding-top: 30px;
}
.ffGridContainer{
    display: grid;
}
.ffRow{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 50px;
}
.ffCard{
    grid-auto-rows: 1fr;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 400px;
}
.ffTitle{
    display: flex;
    justify-content: center;
    margin: 0px;
    padding: 20px;
    padding-bottom: 0px;
    color: var(--Text-Black);
}
.ffContent{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    font-family: "Raleway";
    padding: 20px;
    padding-top: 0px;
    color: var(--Text-Black);
    line-height: 1.5em;
}



  @media screen and (max-width: 1200px) {
    .bioPic{
        padding-left: 0%;
    }
    .bioCard{
        width: 90%;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        margin-top: 0px;
    }
    .imageContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .matchWidth{
        display: none;
    }
    .bioCard-r{
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    .bioText-r{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 0px;
    }
    .bioText{
        display: flex;
        flex-direction: column;
        padding-left: 0px;
        padding-top:20px;
        text-align: right;
    }
    .bioPic{
        display: flex;
        min-width:0px;
        object-fit: contain;
    }
    .ffRow{
        flex-direction: column;
        align-items: center;
    }
    .ffCard{
        max-width: 400px;
        width: auto;
    }
  }