:root{
  --White-Background:#FFFFFF;
  --Text-Black:#424242;
  --Main-Light-Green:#AEBDAC;
  --Dark-Green-Accent:#576855;
  --Medium-Green-Accent:#839C80;
  --Light-Grey-Accent:#FAFAFA;
  --Light-Green-Accent:#d4e0d0;
}

body {
  margin: 0;
  font-family: "PlayfairDisplay",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'LuloClean';   /*Can be any text*/
  src: local("AssistantRegular"), url("./Fonts/LuloCleanOneBold.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: 'Questrial-Regular';   /*Can be any text*/
  src: local("Questrial-Regular"), url("./Fonts/Questrial-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat-Italic';   /*Can be any text*/
  src: local("Montserrat-Italic"), url("./Fonts/Montserrat-Italic-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
}


/* heading */
@font-face {
  font-family: 'PlayfairDisplay';   /*Can be any text*/
  src: local("PlayfairDisplay"), url("./Fonts/PlayfairDisplay-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
}
/* subheading */
@font-face {
  font-family: 'Montserrat';   /*Can be any text*/
  src: local("Montserrat"), url("./Fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
}
/* paragraph */
@font-face {
  font-family: 'Raleway';   /*Can be any text*/
  src: local("Raleway"), url("./Fonts/Raleway-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
}