.missionPage{
    background-color: var(--Light-Grey-Accent);
    display: flex;
    flex-direction: column;
}
.bannerContainer{
    position: relative;
    width: 100%;
    padding-bottom: 40px;
    background-color: var(--Light-Grey-Accent);
}
.bannerTitle{
    justify-content: center;
    display: flex;
    color: var(--Text-Black);
    min-width: 350px;
    font-size: 50px;
    font-weight: bold;
    /* color: #AEBDAC; */
}
.banner{
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    min-height: 500px;
    max-height: 575px;
}
/* .banner{
    width: 100%;
    object-fit: cover;
    object-position: 50% 100%;
    min-height: 500px;
    max-height: 575px;
} */
.quoteImg{
    width: 100%;
    height: 350px;
    object-fit: cover;
}
.quoteContainer{
    position: relative;
    z-index: 2;
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: var(--Light-Grey-Accent);
}
.quoteBackground{
    opacity: 0.85;
    position:absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--White-Background);
    height: 40%;
    width: 40%;
    min-width: 500px;
    top: 45%;
    left:15%;
    
}
.quoteText{
    padding: 50px;
    color: var(--Dark-Green-Accent);
    font-size: 20px;
}
.mission .bannerTextBackground{
    opacity: 1;
    position:absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--White-Background);
    min-width: 490px;
    max-width: 60%;
    height: 180px;
    top: -150px;
    left: -18%;
}
.bannerText{
    font-size: 60px;
    color: var(--Dark-Green-Accent);
    padding: 60px;
    margin: 0px;
    margin-top: -10px;
    width: 100%;
}
.opacity{
    background-color: var(--White-Background);
    opacity: 90%;
    padding: 60px;
    z-index: 10;
}
.mission{
    display: flex;
    position:relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-bottom: -200px;
    bottom: 210px;
    right: -20%;
}

.missionTitle{
    display: flex;
    color: var(--Text-Black);
    text-align: left;
    font-size: 20px;
}
.missionText{
    display: flex;
    color: var(--Text-Black);
    text-align: left;
    font-family: "Questrial-Regular";
    font-size: 16px;
    line-height: 1.5em;
}

.quote{
    display: flex;
    background-color: var(--Light-Grey-Accent);
    flex-direction: column;
}
.quote .processTitle{
    padding-bottom: 20px;
}
@media screen and (max-width: 1200px) {
    .processCard{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        width: 100%;
    }
    .theProcess{
        min-width: 400px;
        max-width: 70%;
        
    }
    .stepTitle{
        display: flex;
        max-width: 100%;
        margin-bottom: 5px;
    }
    .stepText{
        display: flex;
        max-width: 100%;
    }
    .bannerText{
        font-size: 50px;
        color: var(--Dark-Green-Accent);
        padding: 10px;
        margin: 0px;
        margin-top: 10px;
        width: 90%;
    }
    .mission .bannerTextBackground{
        position:absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--White-Background);
        min-width: 400px;
        max-width: 60%;
        height: 180px;
        top: -150px;
        left: -18%;
    }
    
}
@media screen and (max-width: 720px) {
    .quoteBackground{
        opacity: 0.8;
        position:absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--White-Background);
        height: 70%;
        width: 100%;
        min-width: 0px;
        top:10%;
        left:0%;
        
    }
    
    .quote{
        display: flex;
        width: 90%;
    }
    .quote a{
        display: flex;
        width: 100%;
    }
    .quote a .cardButton{
        margin: 0px;
        width: 100%;
        text-align: center;
    }
}