.specPageIntro{
    display: flex;
    flex-direction: row;
    width: 100%;
    height:600px;
    padding-bottom:100px
}
.specPageIntro img{
    display: flex;
    width: 50%;
    object-fit: cover;
}
.specPageIntro div{
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}
.spiText{
    background-color: #AEBDAC;
    color:#FFFFFF;
    font-size: 50px;
    text-align: center;
    padding:1em;
}
.specialtiesPage{
    display:flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items: center;
}
.specialtiesCard{
    display:flex;
    flex-direction: column;
    width:70%;
    padding-bottom: 100px;
    scroll-margin: 100px;
}
.specialtiesContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: flex-start; */
}
.specialtiesTitle{
    text-align: right;
    width: 100%;
    font-size: 50px;
    color: #AEBDAC;
    margin: 0px;
    font-family: 'Montserrat';
}
.specLine{
    display: flex;
    width: 80%;
    align-self: left;
    color:#AEBDAC;
    height: 1px;
    border: 0;
    border-top: 1px solid #AEBDAC;
    margin: 35px;
    margin-left: 20%;
    margin-right:0px;
    padding: 0;
}
.rightLine{
    display: flex;
    width: 80%;
    align-self: right;
    color:#AEBDAC;
    height: 1px;
    border: 0;
    border-top: 1px solid #AEBDAC;
    margin: 35px;
    margin-right: 20%;
    margin-left:0px;
    padding: 0;
}
.liLine{
    display: flex;
    color:#AEBDAC;
    height: 1px;
    border: 0;
    border-top: 1px solid #AEBDAC;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 35%;
    width: 30%;
}
.specPic{
    display: flex;
    object-fit: contain;
    max-height: 500px;
    max-width: 500px;
    transition: 0.3s;
    background-color: white;
}



.specialtiesIntro{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right:60px;
    transition: 0.3s;
}
.specialtiesIntro p{
    text-align:"center";
}
.specialtiesDetail{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.specialtiesUl{
    list-style: none;
    padding-inline-start: 1em;
    font-size:15px;
    font-family:"Raleway";
}

/* .specialtiesUl li::before{
    content:"\25FC";
    font-size: 10px;s
    display: inline-block;
     width: 2em;
    margin-left: -2em;
    color: #AEBDAC;
} */
.hoverContainer{
    position: relative;
    height: 100%;
    flex:1;
    transition: 0.3s;
    background-color: #FAFAFA;
}

.d{
    opacity: 0.0;
    position: absolute;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 90%;
    line-height: 1.5em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
}
.hoverTitle{
    text-align:left;
    color:#AEBDAC;
    font-size: 20px;
    padding: 5px;
    margin-left: 10px;
    font-family: 'Montserrat';
}
.d ul {
    list-style: none;
}
.d ul li{
    text-align: center;
}
.hoverContainer:hover .d{
    opacity: 1.0;
}

.hoverContainer:hover .specPic{
    opacity: 0.0; /* msie */
}
.hoverContainer:hover .specialtiesIntro{
    display: none;
}


@media screen and (max-width: 1100px) {

    .spiText{
        padding:0px;
    }
    .specPageIntro{
        flex-direction: column-reverse;
        height: auto;
        align-items: center;
    }
    .specPageIntro div{
        width: 100%;
    }
    .specPageIntro img{
        width: 100%;
        height: 350px;
    }
    .specialtiesCard{
        width: 90%;
    }
}
@media screen and (max-width:880px) {
    h1{
        font-size: 30px;
    }
    .specialtiesContent{
        flex-direction: column;
    }
    .specialtiesIntro{
        padding: 20px;
        
    }
    #worldTour .specialtiesContent{
        flex-direction:  column-reverse;
    }
    #surpriseMe .specialtiesContent{
        flex-direction: column-reverse;
    }
    .hoverContainer .specPic{
        opacity: 0.2; /* msie */
    }
    .hoverContainer .d{
        opacity: 1.0;
    }
    .specPic{
        width: 100%;
        height: 500px;
        display: flex;
        object-fit: cover;
        transition: 0.3s;
        background-color: white;
    }

}